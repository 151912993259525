/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import ParseContent from 'components/shared/ParseContent'

// Blog
import Blog from 'components/related/Blog'
import Blogik, { BlogConsumer, BlogButton, BlogPagination } from 'components/shared/Blogik'

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <div className="container py-5">
        <Blogik
          settings={{
            limit: 1
          }}
        >
          <BlogConsumer>
            {({ hasPosts, posts, showMoreButton, showLessButton }) => {
              return (
                <div>
                  {hasPosts && (
                    <Blog posts={posts} />
                  )}

                  {!hasPosts && (
                    <ParseContent content="Geen resultaten" />
                  )}

                  <div className="text-center mt-5">
                    {showMoreButton && (
                      <BlogButton>
                        Meer tonen
                      </BlogButton>
                    )}
                    {showLessButton && (
                      <BlogButton increment={false}>
                        Minder tonen
                      </BlogButton>
                    )}
                  </div>

                  <div>
                    <BlogPagination />
                  </div>
                </div>
              )
            }}
          </BlogConsumer>
        </Blogik>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
