/* eslint-disable import/no-unresolved */
import React from 'react'
import { Link } from 'gatsby'

const Blog = ({ posts }) => {
  return (
    <div className="row">
      {posts.map((node) => (
        <div key={node.wordpress_id} className="col-lg-4 d-flex justify-content-center">
          <Post fields={node} />
        </div>
      ))}
    </div>
  )
}

const Post = ({ fields }) => (
  <div>
    <Link to={fields.path}>
      {fields.title}
    </Link>
  </div>
)

export default Blog